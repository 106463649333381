<template>
  <div class="container" v-loading="loading"
       element-loading-text="自动配图中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="page-title">自动配图</div>
    <div class="content">
        <div class="title">（复制菜品名称--点击自动配图即可）</div>
        <div class="menu">
          <div class="example-box">
            <div class="example-title">请按以下格式输入</div>
            <div>菜单类  <<<  第一行为分类</div>
            <div>大海小鲜</div>
            <div><span style="margin-right: 100px;"></span><<<  分类之间空一行</div>
            <div>热菜类</div>
            <div>飘香馋嘴牛蛙</div>
          </div>
          <div class="menu-title">菜单输入</div>
          <el-input
            type="textarea"
            :autosize="{ minRows: 12, maxRows: 15}"
            placeholder="请输入菜单信息"
            clearable
            v-model="textarea">
          </el-input>
        </div>
        <div class="menu" style="margin-top: 15px;margin-bottom: 15px;">
          <el-input
            placeholder="输入订单信息（必填）"
            clearable
            v-model="orderInfo">
          </el-input>
        </div>
      <div class="menu">
        <el-button type="primary" @click="match">自动配图</el-button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        textarea: '',
        orderInfo: '',
        timer: null,
        loading: false
      };
    },
    methods: {
      match(){
        if(!this.textarea){
          this.$message.error('请输入菜单信息');
          return
        }
        if(!this.orderInfo){
          this.$message.error('请输入订单信息');
          return
        }
        var arr1 = this.textarea.split(/\n\n|\r\r|\r\n\r\n/);
        console.log('arr1',arr1)
        let secondNameList = [];
        arr1.forEach((item,index) => {
          var arr2 = item.split(/[(\r\n)\r\n]+/);
          console.log('arr2',arr2)
          secondNameList.push({
            secondName: arr2[0],
            lastNameList: arr2.slice(1,arr2.length)
          })
        })
        console.log('secondNameList',secondNameList)
        const data = {
          orderInfo:this.orderInfo,
          secondNameList
        }
        console.log('data',data)
        this.$http.post("/order/save/orderInfo", data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              const id = res.data.data;
              this.loading = true;
              this.timer = setInterval(()=>{
                this.findResult(id)
              },3000)
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      findResult(id){
        this.$http.post("/order/findResult", {id}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              if(res.data.data.status === 2){
                clearInterval(this.timer);
                // this.loading = false;
                // this.textarea = '';
                // this.orderInfo = '';
                this.goSelectImage(res.data.data)
              }
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      goSelectImage(item){
        this.loading = false;
        this.textarea = '';
        this.orderInfo = '';
        this.$router.push({ name: "selectImage",query:{id: item.orderId,orderKey:item.orderKey}});
      },
    },
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: 90%;
    flex:1;
    overflow: auto;
    .title {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 30px;
      color: red;
      font-size: 26px;
    }
    .menu {
      margin: 0 auto;
      width: 90%;
      display: flex;
      flex-direction: column;
      .menu-title {
        font-size: 16px;
        text-align: left;
        margin-bottom: 10px;
        border-top: 1px dashed #666;
      }
      .example-box {
        text-align: left;
        color: #666;
        margin-bottom: 5px;
        .example-title {
          color: red;

        }
      }
    }
  }
</style>
